var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Custom Toolbar" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCustom) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v("You have freedom to create your own custom toolbar")
      ]),
      _c(
        "quill-editor",
        {
          attrs: { options: _vm.editorOption },
          model: {
            value: _vm.content,
            callback: function($$v) {
              _vm.content = $$v
            },
            expression: "content"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "toolbar", id: "toolbar" }, slot: "toolbar" },
            [
              _c("button", { staticClass: "ql-bold" }, [_vm._v(" Bold ")]),
              _c("button", { staticClass: "ql-italic" }, [_vm._v(" Italic ")]),
              _c("select", { staticClass: "ql-size" }, [
                _c("option", { attrs: { value: "small" } }),
                _c("option", { attrs: { selected: "" } }),
                _c("option", { attrs: { value: "large" } }),
                _c("option", { attrs: { value: "huge" } })
              ]),
              _c("select", { staticClass: "ql-font" }, [
                _c("option", { attrs: { selected: "selected" } }),
                _c("option", { attrs: { value: "serif" } }),
                _c("option", { attrs: { value: "monospace" } })
              ]),
              _c("button", {
                staticClass: "ql-script",
                attrs: { value: "sub" }
              }),
              _c("button", {
                staticClass: "ql-script",
                attrs: { value: "super" }
              }),
              _c(
                "button",
                {
                  staticClass: "w-auto btn btn-primary",
                  on: { click: _vm.customButtonClick }
                },
                [_vm._v(" Click here ")]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }